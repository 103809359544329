import './styles.css'
import AppCard from '../components/appCard/AppCard'
import { TypeAnimation } from 'react-type-animation';

const Home = () => {
    const skills = [
        'Swift',
        'UIKit',
        'SwiftUI',
        'Firebase',
        'Git',
        'React',
        'CSS',
        'JavaScript',
        'RevenueCat',
        'StoreKit',
        'Stripe'
    ]

    const budgetifySkills = [
        'Swift',
        'SwiftUI',
        'Firebase',
        'RevenueCat'
    ]

    const sayabisaSkills = [
        'Swift',
        'UIKit',
        'Firebase',
        'StoreKit'
    ]

    const heartofoceanSkills = [
        'Swift',
        'SwiftUI',
        'Firebase',
    ]

    const stringsSkills = [
        'macOS',
        'Swift',
        'SwiftUI',
        'RevenueCat',
        'App Store Connect API'
    ]

    return (
        <div className='bg-black flex flex-col h-[100%] px-[80px] pt-[40px] overflow-y-auto min-w-[1000px]'>
            <div className='flex pb-[40px] max-w-[700px]'>
                <div className='flex flex-col gap-4'>
                    <div className='flex flex-col gap-0'>
                        <p className='text-white/75 font-medium text-[20px]'>Hello! My name is</p>

                        <TypeAnimation
                            className='text-white font-medium text-[36px] m-0'
                            sequence={[
                                'Joses Solmaximo'
                            ]}
                            wrapper="p"
                            cursor={false}
                            repeat={1}
                        />

                        <p className='text-white/75 font-medium text-[20px] m-0'>I'm an iOS developer with a passion for creating elegant and efficient solutions for complex problems. With over 3 years of experience in the industry, I have extensive knowledge of Swift, Xcode, and other development tools.</p>
                    </div>

                    <div className="flex flex-wrap" style={{ columnGap: '8px', rowGap: '8px' }}>
                        {skills.map((skill) => {
                            return (
                                <p className="bg-white rounded-full py-2 px-4 font-medium">{skill}</p>
                            )
                        })}
                    </div>
                </div>

                <div className='h-[20px] flex gap-6 items-center ml-auto'>
                    <a target='_blank' href='mailto: josessolmaximo.developer@gmail.com' rel='noreferrer'>
                        <img className='min-w-[25px] h-[25px]' src='mail-icon.png' alt='Mail'></img>
                    </a>

                    <a target='_blank' href='https://github.com/josessolmaximo' rel='noreferrer'>
                        <img className='min-w-[20px] h-[20px]' src='github-logo.png' alt='GitHub'></img>
                    </a>

                    <a target='_blank' href='https://www.linkedin.com/in/joses-solmaximo/' rel='noreferrer'>
                        <img className='min-w-[20px] h-[20px]' src='linkedin-logo.png' alt='LinkedIn'></img>
                    </a>
                </div>
            </div>

            <div className='flex flex-col gap-8 pb-4'>
                <AppCard
                    images={[
                        'budgetify/budgetify-0.png',
                        'budgetify/budgetify-1.png',
                        'budgetify/budgetify-2.png',
                        'budgetify/budgetify-3.png',
                        'budgetify/budgetify-4.png',
                        'budgetify/budgetify-5.png',
                        'budgetify/budgetify-6.png',
                        'budgetify/budgetify-7.png',
                        'budgetify/budgetify-8.png',
                        'budgetify/budgetify-9.png',
                    ]}
                    appName='Budgetify'
                    appIcon='budgetify/budgetify-icon.png'
                    role='Developer'
                    description='Budgetify is an expense tracker app that I created to address the shortcomings of existing expense trackers.
                The app is designed to be simple to use with a minimalist design.
                I developed it myself, using SwiftUI and Firebase as my backend.'
                    skills={budgetifySkills}
                    appStoreLink='https://apps.apple.com/us/app/budgetify-expense-tracker/id6443894407'
                    imagePadding={0}
                />

                <AppCard
                    images={[
                        'blackout/blackout-redact.png',
                        'blackout/blackout-blur.png',
                        'blackout/blackout-blend.png',
                        'blackout/blackout-highlight.png',
                        'blackout/blackout-photos.png',
                        'blackout/blackout-dark.png',
                    ]}
                    appName='Blackout'
                    appIcon='blackout/blackout-icon.png'
                    role='Developer'
                    description={`Blackout is a photo editing app which automatically detects text in an image and allows you to censor it.
                It includes tools like redact, blur, pixelate, blend, highlight, and underline.
                `}
                    skills={[
                        'Swift',
                        'SwiftUI',
                        'UIKit',
                        'VisionKit',
                        'RevenueCat',
                    ]}
                    appStoreLink='https://apps.apple.com/us/app/blackout-censor-blur-redact/id1672966131'
                    imagePadding={0}
                />

                <AppCard
                    images={[
                        'heartofocean/heartofocean-onboarding.png',
                        'heartofocean/heartofocean-matching.png',
                        'heartofocean/heartofocean-chat.png',
                        'heartofocean/heartofocean-account.png',
                        'heartofocean/heartofocean-personality.png',
                        'heartofocean/heartofocean-home.png',
                        'heartofocean/heartofocean-join.png',
                        'heartofocean/heartofocean-react.png',
                    ]}
                    appName='Heart of Ocean'
                    appIcon='heartofocean/heartofocean-icon.png'
                    role='Developer'
                    description={`A dating app which uses personality types and experiences to match users. 
                    It features a chat where you can react to other user’s messages with reactions, likes or dislikes. 
                    There’s also an interactive personality test that you can take to increase your match accuracy`
                    }
                    skills={heartofoceanSkills}
                    imagePadding={10}
                />

                <AppCard
                    images={[
                        'strings/strings-translator.png',
                        'strings/strings-metadata.png',
                        'strings/strings-review.png',
                    ]}
                    appName='Strings'
                    appIcon='strings/strings-icon.png'
                    role='Developer'
                    description={`An app aimed to help developers localize their apps, bulk edit their app store connect metadata, and translate or reply to customer reviews`
                    }
                    skills={stringsSkills}
                    imagePadding={10}
                />

                <AppCard
                    images={[
                        'sayabisa/sayabisa-study.png',
                        'sayabisa/sayabisa-browse.png',
                        'sayabisa/sayabisa-video.png',
                        'sayabisa/sayabisa-download.png',
                        'sayabisa/sayabisa-ebook.png',
                        'sayabisa/sayabisa-quiz.png',
                        'sayabisa/sayabisa-flashcard.png',
                        'sayabisa/sayabisa-exercise.png',
                        'sayabisa/sayabisa-schedule.png',
                        'sayabisa/sayabisa-collection.png',
                        'sayabisa/sayabisa-carikata.png',
                        'sayabisa/sayabisa-taptap.png',
                        'sayabisa/sayabisa-spellingbee.png',
                    ]}
                    appName='SayaBisa'
                    appIcon='sayabisa/sayabisa-icon.png'
                    role='iOS Developer'
                    description={`SayaBisa is a fun and interactive educational app designed for children
                to learn basic concepts in a playful and engaging way.
                The app is designed to be intuitive and easy to use, with colorful and attractive
                visuals that appeal to children's curiosity and creativity.
                I worked on the iOS version of this app along with a team of 5.`}
                    skills={sayabisaSkills}
                    appStoreLink='https://apps.apple.com/in/app/geniora-sayabisa-appedukasi/id1604704947'
                    playStoreLink='https://play.google.com/store/apps/details?id=com.geniora.genioramobilev3'
                    imagePadding={0}
                />

                <AppCard
                    images={[
                        'bookfigaro/bookfigaro-browse.png',
                        'bookfigaro/bookfigaro-barber.png',
                        'bookfigaro/bookfigaro-gallery.png',
                        'bookfigaro/bookfigaro-timepicker.png',
                        'bookfigaro/bookfigaro-payment.png',
                        'bookfigaro/bookfigaro-booked.png',
                        'bookfigaro/bookfigaro-appointment.png',
                        'bookfigaro/bookfigaro-review-compliment.png',
                        'bookfigaro/bookfigaro-review-tips.png',
                        'bookfigaro/bookfigaro-filter.png',
                        'bookfigaro/bookfigaro-whenandwhere.png',
                    ]}
                    appName='Book Figaro'
                    appIcon='bookfigaro/bookfigaro-icon.png'
                    role='Developer'
                    description='Book Figaro is an app that changes the way customers book appointments 
                with their favorite barbers. Powered by Firebase backend and integrated with Stripe, and features like scheduling, notifications, and browsing, it provides a seamless and secure experience'
                    skills={[
                        'Swift',
                        'SwiftUI',
                        'Firebase',
                        'Stripe',
                    ]}
                    appStoreLink='https://apps.apple.com/gb/app/book-figaro/id6446124585'
                    imagePadding={0}
                />

                <AppCard
                    images={[
                        'hifigaro/hifigaro-barber.png',
                        'hifigaro/hifigaro-booked.png',
                        'hifigaro/hifigaro-appointment.png',
                        'hifigaro/hifigaro-earnings.png',
                        'hifigaro/hifigaro-reviews.png',
                        'hifigaro/hifigaro-reviews-reply.png',
                        'hifigaro/hifigaro-report.png',
                        'hifigaro/hifigaro-compliment.png',
                        'hifigaro/hifigaro-workinghours.png',
                        'hifigaro/hifigaro-workinghours-sheet.png',
                        'hifigaro/hifigaro-service-add.png',
                    ]}
                    appName='Hi Figaro'
                    appIcon='hifigaro/hifigaro-icon.png'
                    role='Developer'
                    description='Hi Figaro is the barber app designed to work with Book Figaro. Barbers can effortlessly 
                manage their schedules, track appointments, and communicate with their clients. Hi Figaro is built with
                Firebase and uses Stripe to process payments and withdrawals'
                    skills={[
                        'Swift',
                        'SwiftUI',
                        'Firebase',
                        'Stripe',
                    ]}
                    appStoreLink='https://apps.apple.com/gb/app/hi-figaro/id6446124695'
                    imagePadding={0}
                />
            </div>
        </div>
    )
}

export default Home