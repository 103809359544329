import ImageCarousel from "../ImageCarousel"

interface AppCardProps {
    images: string[],
    appName: string,
    appIcon: string,
    role: string,
    description: string,
    skills: string[],
    appStoreLink?: string,
    playStoreLink?: string,
    imagePadding: number
}

const AppCard = ({ images, appName, appIcon, role, description, skills, appStoreLink, playStoreLink, imagePadding = 0 }: AppCardProps) => {
    return (
        <div className='flex flex-col gap-0 rounded-[9px] border border-white/20'>
            <div className='flex gap-2 items-center bg-[#262626] rounded-t-[8px] h-[32px] px-4 border-b border-[#030303]'>
                <div className='bg-[#FE5E56] rounded-full w-3 h-3'></div>
                <div className='bg-[#FDBB2D] rounded-full w-3 h-3'></div>
                <div className='bg-[#28C73F] rounded-full w-3 h-3'></div>
            </div>

            <div className='flex bg-[#1C1C1C] h-full rounded-b-[8px] '>
                <div className="flex flex-col gap-2 w-[35%] pt-4 pb-4 px-4">
                    <div className="flex gap-4 items-center">
                        <img className='min-w-[40px] h-[40px] rounded-md' src={appIcon} alt={`${appName} Icon`}></img>
                        <p className='text-white font-semibold text-[36px]'>{appName}</p>
                    </div>

                    <p className="text-white/75 text-[20px] font-medium m-0">{description}</p>

                    <div className="flex flex-wrap pb-4 pt-2" style={{ columnGap: '8px', rowGap: '8px' }}>
                        {skills.map((skill) => {
                            return (
                                <p className="bg-white rounded-full py-2 px-4 font-medium">{skill}</p>
                            )
                        })}
                    </div>

                    <div className="flex gap-4">
                        {appStoreLink && (
                            <a target='_blank' href={appStoreLink} rel='noreferrer'>
                                <img className="border border-white w-[165px] h-[50px] rounded-md" src='app-store-icon.png' alt='App Store Button'></img>
                            </a>
                        )}

                        {playStoreLink && (
                            <a target='_blank' href={playStoreLink} rel='noreferrer'>
                                <img className="border border-white w-[165px] h-[50px] rounded-md" src='google-play-icon.png' alt='Play Store Button'></img>
                            </a>
                        )}
                    </div>
                </div>

                <div className="bg-[#030303] w-[1px] max-h-[1000px]">

                </div>

                <div className="flex gap-4 w-[65%] overflow-x-scroll px-4 py-2 items-center">
                    {images.map((image, index) => {
                        return (
                            <img
                                key={index}
                                src={image}
                                style={{
                                    maxHeight: '520px',
                                    paddingTop: '10px',
                                    transition: 'opacity 0.4s ease-in-out',
                                    paddingLeft: `${imagePadding}px`,
                                    paddingBottom: `${imagePadding}px`
                                }}
                                alt={`${appName} App Screenshot ${index}`}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default AppCard