// import './App.css';
import Home from './pages/home/home'

function App() {
  return (
    <Home></Home>
  );
}

export default App;
